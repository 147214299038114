import styled from "styled-components";

export const Container = styled.div`
  background-color: #F1F0EF;
  padding-top: 45px;
  padding-bottom: 20px;
  padding-left: ${({ theme }) =>
    theme.layout.content.paddingHorizontal.desktop}px;
  padding-right: ${({ theme }) =>
    theme.layout.content.paddingHorizontal.desktop}px;

  @media only screen and (max-width: 768px) {
    padding-left: ${({ theme }) =>
    theme.layout.content.paddingHorizontal.ipad}px;
    padding-right: ${({ theme }) =>
    theme.layout.content.paddingHorizontal.ipad}px;
  }
  @media only screen and (max-width: 560px) {
    padding-top: 27px;
  }
`;

export const SectionTitleWrap = styled.div`
  @media only screen and (max-width: 560px) {
    display: flex;
    justify-content: center;
    align-items: center;
    & div {
      max-width: 286px;
      font-size: 20px;
    }
  }
`;

export const StepsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: nowrap;
  padding-top: 26px;
  gap: 0.8em;

  @media only screen and (max-width: 768px) {
    justify-content: space-evenly;
    gap: 0;
    flex-flow: row wrap;
  }
  @media only screen and (max-width: 560px) {
    padding-top: 11px;
  }
`;
